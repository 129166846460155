@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		@apply text-gray-700;
	}
}
